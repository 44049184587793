import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FontIcon from 'src/components/FontIcon';

import theme from 'src/styles/components/Button.module.scss';

class Button extends Component {
    getLevel = () => {
        if (this.props.primary) return 'primary';
        if (this.props.accent) return 'accent';
        return 'neutral';
    }

    getShape = () => {
        if (this.props.raised) return 'raised';
        if (this.props.floating) return 'floating';
        return 'flat';
    }

    handleMouseUp = (event) => {
        this.buttonNode.blur();
        if (this.props.onMouseUp) this.props.onMouseUp(event);
    };

    handleMouseLeave = (event) => {
        this.buttonNode.blur();
        if (this.props.onMouseLeave) this.props.onMouseLeave(event);
    };

    render() {
        const {
            accent,
            children,
            className,
            flat,
            floating,
            href,
            icon,
            inverse,
            label,
            mini,
            neutral,
            primary,
            raised,
            transparent,
            type,
            ...others
        } = this.props;

        const element = href ? 'a' : 'button';
        const level = this.getLevel();
        const shape = this.getShape();
        const mouseEvents = {
            onMouseUp: this.handleMouseUp,
            onMouseLeave: this.handleMouseLeave
        };

        const classes = classnames(theme.button, [theme[shape]], {
            [theme[level]]: neutral,
            [theme.mini]: mini,
            [theme.inverse]: inverse,
            [theme.transparent]: transparent
        }, className);

        const props = {
            ...others,
            ...mouseEvents,
            href,
            'ref': (node) => { this.buttonNode = node; },
            'className': classes,
            'disabled': this.props.disabled,
            'type': !href ? type : null,
            'data-sdk': 'button'
        };

        const buttonElement = React.createElement(
            element, props,
            icon ? <FontIcon className={theme.icon} value={icon} /> : null,
            label,
            children
        );

        return others.onMouseEnter && this.props.disabled
            ? <span {...mouseEvents}>{buttonElement}</span>
            : buttonElement;
    }
}

Button.propTypes = {
    accent: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    flat: PropTypes.bool,
    floating: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    inverse: PropTypes.bool,
    transparent: PropTypes.bool,
    label: PropTypes.string,
    mini: PropTypes.bool,
    neutral: PropTypes.bool,
    onMouseLeave: PropTypes.func,
    onMouseUp: PropTypes.func,
    primary: PropTypes.bool,
    raised: PropTypes.bool,
    type: PropTypes.string
};

Button.defaultProps = {
    accent: false,
    className: '',
    flat: false,
    floating: false,
    mini: false,
    neutral: true,
    primary: false,
    raised: false,
    transparent: false,
    type: 'button'
};

export default Button;
