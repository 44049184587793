import { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import noop from 'lodash/noop';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';

import theme from 'src/styles/components/Modal.module.scss';

class Modal extends Component {
    handleDismiss = throttle((event) => {
        event.preventDefault();
        event.stopPropagation();

        const {
            topMessage: { onDismiss = noop },
            onDismissMessage
        } = this.props;

        onDismissMessage();
        onDismiss();
    }, 2000)

    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
            initialPosition: null,
            topMessage: {}
        };
    }

    componentDidMount() {
        const { topMessage } = this.props;

        if (topMessage.message) {
            this.setState({
                isActive: true,
                initialPosition: typeof topMessage.position !== 'undefined' ? topMessage.position : null,
                topMessage
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { topMessage } = this.props;

        if (!topMessage.message && topMessage.message !== prevProps.topMessage.message) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isActive: false
            });
        } else if (topMessage.message && topMessage.message !== prevProps.topMessage.message) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isActive: true,
                initialPosition: typeof topMessage.position !== 'undefined' ? topMessage.position : null,
                topMessage
            });
        }
    }

    handleKeyPress = (event, callback) => {
        const { KEY_CODES } = this.context.container;
        const { ENTER, SPACE } = KEY_CODES;

        if (event.keyCode === ENTER || event.keyCode === SPACE) {
            event.preventDefault();
            callback();
        }
    };

    render() {
        const { isDarkMode, intl } = this.props;
        const { isActive, initialPosition } = this.state;

        const {
            addon,
            isDismissButtonEnabled,
            actions,
            isBackdropClickEnabled,
            isEscKeyDownEnabled,
            isFullscreenEnabled,
            isDefaultOkEnabled,
            hasFixedContent,
            type,
            message,
            title,
            subtitle,
            className,
            contentClassName
        } = this.state.topMessage;

        const renderActions = isDefaultOkEnabled && !actions
            ? <Button onClick={this.handleDismiss} raised accent label={intl.formatMessage({ id: 'App.ok' })} />
            : actions;

        return (
            <Dialog
              className={classnames(className, theme.dialog, { [theme.fullscreen]: isFullscreenEnabled })}
              onOverlayClick={isBackdropClickEnabled ? this.handleDismiss : noop}
              onEscKeyDown={isEscKeyDownEnabled ? this.handleDismiss : noop}
              active={isActive}
              initialPosition={initialPosition}
              type={type}
              isDarkMode={isDarkMode}
            >
                <div className={theme.header}>
                    <div className={theme.headerContent}>
                        <h3 className={theme.subtitle}>{subtitle}</h3>
                        <h2 className={theme.title}>{title}</h2>
                    </div>
                    <div className={theme.headerActions}>
                        <div className={theme.iconActions}>
                            {isDismissButtonEnabled && (
                                <Button
                                  floating
                                  mini
                                  icon="icon-close"
                                  onClick={this.handleDismiss}
                                  className={theme.closeIcon}
                                />
                            )}
                        </div>
                        <div className={theme.addon}>
                            {addon}
                        </div>
                    </div>
                </div>
                <div className={classnames(contentClassName, { [theme.content]: !hasFixedContent })}>
                    {message}
                </div>
                {renderActions && (
                    <div className={theme.footer}>
                        {renderActions}
                    </div>
                )}
            </Dialog>
        );
    }
}

Modal.propTypes = {
    topMessage: PropTypes.object,
    onDismissMessage: PropTypes.func,
    isDarkMode: PropTypes.bool,
    intl: PropTypes.object.isRequired
};

Modal.defaultProps = {
    onDismissMessage: noop,
    topMessage: {}
};

export default injectIntl(Modal);
