import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'src/components/Button';

import theme from 'src/styles/components/Notification.module.scss';

class Notification extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
        mounted: false
    }

    componentDidMount() {
        this.setState({
            mounted: true
        });

        if (this.props.active && this.props.timeout) {
            this.scheduleTimeout();
        }
    }

    componentDidUpdate() {
        if (this.props.active && this.props.timeout) {
            this.scheduleTimeout();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.currentTimeout);
    }

    scheduleTimeout = () => {
        const { onTimeout, timeout } = this.props;
        if (this.currentTimeout) {
            clearTimeout(this.currentTimeout);
        }

        this.currentTimeout = setTimeout(() => {
            if (onTimeout) {
                onTimeout();
            }
            this.currentTimeout = null;
        }, timeout);
    }

    render() {
        const { action, active, children, label, onClick, type } = this.props;
        const { mounted } = this.state;

        const className = classnames([theme.snackbar, theme[type]], {
            [theme.active]: active
        }, this.props.className);

        if (!mounted) {
            return null;
        }

        return (
            ReactDOM.createPortal(
                <>
                    <div className={theme.portal}>
                        <div data-sdk="snackbar" className={className}>
                            <span className={theme.label}>
                                {label}
                                {children}
                            </span>
                            {action ? <Button className={theme.button} accent label={action} onClick={onClick} /> : null}
                        </div>
                    </div>
                </>,
                document.body
            )
        );
    }
}

Notification.propTypes = {
    action: PropTypes.string,
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    onClick: PropTypes.func,
    onTimeout: PropTypes.func,
    theme: PropTypes.shape({
        accept: PropTypes.string,
        active: PropTypes.string,
        button: PropTypes.string,
        cancel: PropTypes.string,
        label: PropTypes.string,
        snackbar: PropTypes.string,
        warning: PropTypes.string,
        error: PropTypes.string
    }),
    timeout: PropTypes.number,
    type: PropTypes.oneOf(['accept', 'cancel', 'warning', 'error'])
};

export default Notification;
