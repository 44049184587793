import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';

import Button from 'src/components/Button';
import IconButton from 'src/components/IconButton';
import Notification from 'src/components/Notification';

import theme from 'src/styles/components/Notifications.module.scss';

class Notifications extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
        isSnackbarActive: true
    }

    handleDismiss = throttle(() => {
        const { onDismissMessage, topMessage: { onDismiss } } = this.props;

        this.setState({
            isSnackbarActive: false
        });

        setTimeout(() => {
            if (onDismiss) {
                onDismiss();
            }

            onDismissMessage();

            this.setState({
                isSnackbarActive: true
            });
        }, 1000);
    }, 2000);

    handleButtonClick = ({ dontDismiss, event }) => (e) => {
        if (!dontDismiss) {
            this.handleDismiss();
        }

        event(e);
    }

    handleDismissOnMessage = (shouldDismissOnMessageClick) => {
        if (shouldDismissOnMessageClick) {
            this.handleDismiss();
        }
    }

    renderMessage = () => {
        const { topMessage } = this.props;
        const {
            title,
            message,
            isDismissButtonEnabled,
            actions,
            shouldDismissOnMessageClick
        } = topMessage;

        if (!topMessage || isEmpty(topMessage)) {
            return null;
        }

        return (
            <div
              className={theme.container}
              role="button"
              tabIndex={0}
              onClick={() => this.handleDismissOnMessage(shouldDismissOnMessageClick)}
            >
                {isDismissButtonEnabled && (
                    <IconButton
                      icon="icon-close"
                      onClick={this.handleDismiss}
                      className={theme.closeIcon}
                    />
                )}
                {title && <h3 className={theme.title}>{title}</h3>}
                {message && (
                    <div className={theme.content}>
                        {message}
                    </div>
                )}
                {actions && (
                    <div className={theme.actions}>
                        {actions.map(({ onClick, label, dontDismiss, ...props }) => (
                            <Button
                              label={label}
                              onClick={this.handleButtonClick({ dontDismiss, event: onClick })}
                              {...props}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { topMessage, timeout: defaultTimeout } = this.props;
        const { isSnackbarActive } = this.state;

        const message = topMessage || {};

        const {
            timeout: messageTimeout,
            isDismissButtonEnabled,
            type
        } = message;

        const timeout = isNumber(messageTimeout)
            ? messageTimeout
            : defaultTimeout;

        const isActive = !isEmpty(topMessage) && isSnackbarActive;

        return (
            <Notification
              action={!isDismissButtonEnabled ? 'Dismiss' : ''}
              active={isActive}
              timeout={timeout}
              onClick={this.handleDismiss}
              onTimeout={this.handleDismiss}
              type={type}
            >
                {this.renderMessage()}
            </Notification>
        );
    }
}

Notifications.propTypes = {
    topMessage: PropTypes.object,
    onDismissMessage: PropTypes.func,
    timeout: PropTypes.number
};

Notifications.defaultProps = {
    onDismissMessage: noop,
    timeout: 5000,
    topMessage: {}
};

export default Notifications;
