import { connect } from 'react-redux';
import { dismissFirstNotification, selectFirstNotification } from 'src/modules/Notification';

const mapDispatchToProps = {
    onDismissMessage: dismissFirstNotification
};

const mapStateToProps = (state) => ({
    topMessage: selectFirstNotification(state)
});

export default connect(mapStateToProps, mapDispatchToProps);
