import { connect } from 'react-redux';
import { dismissFirstMessage, selectFirstMessage } from 'src/modules/Modal';

const mapDispatchToProps = {
    onDismissMessage: dismissFirstMessage
};

const mapStateToProps = (state) => ({
    topMessage: selectFirstMessage(state)
});

export default connect(mapStateToProps, mapDispatchToProps);
