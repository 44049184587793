import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import theme from 'src/styles/components/Overlay.module.scss';

class Overlay extends Component {
    componentDidMount() {
        const { active, lockScroll, onEscKeyDown } = this.props;
        if (onEscKeyDown) document.body.addEventListener('keydown', this.handleEscKey);
        if (active && lockScroll) document.body.style.overflow = 'hidden';
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lockScroll) {
            const becomingActive = this.props.active && !prevProps.active;
            const becomingUnactive = !this.props.active && prevProps.active;

            if (becomingActive) {
                document.body.style.overflow = 'hidden';
            }

            if (becomingUnactive && !document.querySelectorAll('[data-sdk="overlay"]')[1]) {
                document.body.style.overflow = '';
            }
        }

        if (this.props.onEscKeyDown) {
            if (this.props.active && !prevProps.active) {
                document.body.addEventListener('keydown', this.handleEscKey);
            } else if (!this.props.active && prevProps.active) {
                document.body.removeEventListener('keydown', this.handleEscKey);
            }
        }
    }

    componentWillUnmount() {
        if (this.props.active && this.props.lockScroll) {
            if (!document.querySelectorAll('[data-sdk="overlay"]')[1]) {
                document.body.style.overflow = '';
            }
        }

        if (this.props.onEscKeyDown) {
            document.body.removeEventListener('keydown', this.handleEscKey);
        }
    }

    handleEscKey = (e) => {
        if (this.props.active && this.props.onEscKeyDown && e.which === 27) {
            this.props.onEscKeyDown(e);
        }
    }

    handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    render() {
        const { active, className, lockScroll, onEscKeyDown, ...other } = this.props;

        return (
            <div
              {...other}
              onClick={this.handleClick}
              className={classnames(theme.overlay, {
                  [theme.active]: active
              }, className)}
              role="presentation"
            />
        );
    }
}

Overlay.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    lockScroll: PropTypes.bool,
    onClick: PropTypes.func,
    onEscKeyDown: PropTypes.func
};

Overlay.defaultProps = {
    lockScroll: true
};

export default Overlay;
