import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'src/components/Button';
import Overlay from 'src/components/Overlay';

import theme from 'src/styles/components/Dialog.module.scss';

import withActivableRenderer from 'src/hocs/withActivableRenderer';

const Dialog = (props) => {
    const ref = useRef();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        ref.current = document.body;
        setMounted(true);
    });

    if (!mounted) {
        return <></>;
    }

    const actions = props.actions.map((action, idx) => {
        const className = classnames(theme.button, { [action.className]: action.className });
        return <Button key={idx} {...action} className={className} />; // eslint-disable-line
    });

    const className = classnames([theme.dialog, theme[props.type]], {
        [theme.active]: props.active,
        [theme.customInitialPosition]: props.initialPosition
    }, props.className);

    return ReactDOM.createPortal(
        <>
            <div className={classnames(theme.wrapper, { 'dark': props.isDarkMode })}>
                <Overlay
                  active={props.active}
                  className={theme.overlay}
                  onClick={props.onOverlayClick}
                  onEscKeyDown={props.onEscKeyDown}
                  onMouseDown={props.onOverlayMouseDown}
                  onMouseMove={props.onOverlayMouseMove}
                  onMouseUp={props.onOverlayMouseUp}
                />
                <div
                  data-sdk="dialog"
                  className={classnames(className, 'dark')}
                  style={props.initialPosition}
                >
                    <section className={theme.body}>
                        {props.title ? <h6 className={theme.title}>{props.title}</h6> : null}
                        {props.children}
                    </section>
                    {actions.length
                        ? (
                            <nav className={theme.navigation}>
                                {actions}
                            </nav>
                        )
                        : null}
                </div>
            </div>
        </>,
        ref.current
    );
};

Dialog.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        className: PropTypes.string,
        label: PropTypes.string,
        children: PropTypes.node
    })),
    initialPosition: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number,
        right: PropTypes.number,
        bottom: PropTypes.number
    }),
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    onEscKeyDown: PropTypes.func,
    onOverlayClick: PropTypes.func,
    onOverlayMouseDown: PropTypes.func,
    onOverlayMouseMove: PropTypes.func,
    onOverlayMouseUp: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
    isDarkMode: PropTypes.bool
};

Dialog.defaultProps = {
    actions: [],
    active: false,
    type: 'normal'
};

export default withActivableRenderer()(Dialog);
