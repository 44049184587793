import React, { Component } from 'react';
import PropTypes from 'prop-types';

const withActivableRenderer = (options = { delay: 500 }) => (ActivableComponent) => {
    class ActivableRenderer extends Component {
        constructor(props) {
            super(props);

            this.state = {
                active: props.active,
                rendered: props.active
            };
        }

        componentDidUpdate(prevProps) {
            if (!prevProps.active && this.props.active) this.renderAndActivate();
            if (prevProps.active && !this.props.active) this.deactivateAndUnrender();
        }

        componentWillUnmount() {
            clearTimeout(this.activateTimeout);
            clearTimeout(this.unrenderTimeout);
        }

        deactivateAndUnrender() {
            this.setState({ rendered: true, active: false }, () => {
                this.unrenderTimeout = setTimeout(() => {
                    this.setState({ rendered: false });
                    this.unrenderTimeout = null;
                }, this.props.delay);
            });
        }

        renderAndActivate() {
            if (this.unrenderTimeout) clearTimeout(this.unrenderTimeout);
            this.setState({ rendered: true, active: false }, () => {
                this.activateTimeout = setTimeout(() => this.setState({ active: true }), 20);
            });
        }

        render() {
            const { delay, ...others } = this.props;
            const { active, rendered } = this.state;

            return rendered
                ? <ActivableComponent {...others} active={active} />
                : null;
        }
    }

    ActivableRenderer.propTypes = {
        active: PropTypes.bool.isRequired,
        children: PropTypes.node,
        delay: PropTypes.number
    };

    ActivableRenderer.defaultProps = {
        delay: options.delay
    };

    return ActivableRenderer;
};

export default withActivableRenderer;
